import { useTranslation } from "react-i18next";

import TeamBgImage from "@/assets/Img/Team/bg_team.jpg";

/* Leadersship Images */
import EnriqueHuesca from "@/assets/Img/Team/Leadership/Enrique_Huesca.png";
import DanielHuerta from "@/assets/Img/Team/Leadership/Daniel_Huerta.png";
import PaolaCortes from "@/assets/Img/Team/Leadership/Paola_Cortes.png";
import EdgarHerrera from "@/assets/Img/Team/Leadership/Edgar_Herrera.png";
import EduardoRivera from "@/assets/Img/Team/Leadership/Eduardo_Rivera.png";

import MarianaHuesca from "@/assets/Img/Team/Team_Leaders/Maria_Huesca.png";
import SalvadorRochin from "@/assets/Img/Team/Team_Leaders/Salvador_Rochin.png";
import GustavoHerrera from "@/assets/Img/Team/Team_Leaders/Gustavo_Herrera.png";
import AndresLeal from "@/assets/Img/Team/Team_Leaders/Andres_Leal.png";
import AlejandroCreel from "@/assets/Img/Team/Team_Leaders/Alejandro_Creel.png";
import JuanDavid from "@/assets/Img/Team/Team_Leaders/Juan_Gomez.png";
import PerlaAlvarado from "@/assets/Img/Team/Team_Leaders/Perla_Alvarado.png";
import MarliaAlarcon from "@/assets/Img/Team/Team_Leaders/Marlia_Alarcon.png";
import MarianaCuervo from "@/assets/Img/Team/Team_Leaders/Mariana_Cuervo.png";
import BoomerCarr from "@/assets/Img/Team/Team_Leaders/Boomer.png";
import IsraelSosa from "@/assets/Img/Team/Team_Leaders/Israel_Sosa.png";
import RenneTriay from "@/assets/Img/Team/Team_Leaders/Renee_Triay.png";
import IlseMurillo from "@/assets/Img/Team/Team_Leaders/Ilse_Murillo.png";
import LeadersCard from "@/components/LeadersCard";

export default function AboutUsPage() {
  const { t } = useTranslation("global");

  return (
    <>
      <div className="w-full flex flex-col">
        <div className="relative items-center h-72 md:h-[32rem] lg:h-[40rem]">
          <img
            className="w-full h-full absolute top-0 left-0 object-cover object-top"
            src={TeamBgImage}
            alt="About us"
          />
          <div className="absolute w-[90%] h-full flex flex-col justify-center top-0 left-1/2 transform -translate-x-1/2">
            <h1 className="text-4xl lg:text-6xl 2xl:text-7xl text-left text-white font-semibold lg:-translate-y-10 mb-4">
              {t("AboutUs.Heading")}
            </h1>
            <p className="text-sm lg:text-xl 2xl:text-2xl text-white font-extralight lg:-translate-y-10">
              {t("AboutUs.Subtitle1")}
            </p>
            <p className="text-sm lg:text-xl 2xl:text-2xl text-white font-extralight lg:-translate-y-10">
              {t("AboutUs.Subtitle2")}
            </p>
          </div>
        </div>

        <div className="w-full flex flex-col items-center py-6 gap-6 lg:gap-10 -translate-y-8 rounded-t-3xl lg:-mb-12 lg:-translate-y-12 lg:rounded-t-large bg-white">
          <div className="w-full lg:w-[91%] 2xl:max-w-screen-2xl flex flex-col gap-4 items-center px-6 lg:px-10">
            <h1 className="text-3xl md:text-5xl font-bold w-full mt-4 text-center mb-10">
              {t("AboutUs.LeadershipSection")}
            </h1>
            <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-10">
              <LeadersCard
                name={t("AboutUs.Leadership1.name")}
                position={t("AboutUs.Leadership1.position")}
                imageSrc={EnriqueHuesca}
              />
              <LeadersCard
                name={t("AboutUs.Leadership2.name")}
                position={t("AboutUs.Leadership2.position")}
                imageSrc={DanielHuerta}
              />
              <LeadersCard
                name={t("AboutUs.Leadership4.name")}
                position={t("AboutUs.Leadership4.position")}
                imageSrc={EdgarHerrera}
              />
              <LeadersCard
                name={t("AboutUs.Leadership5.name")}
                position={t("AboutUs.Leadership5.position")}
                imageSrc={EduardoRivera}
              />
              <LeadersCard
                name={t("AboutUs.Leadership3.name")}
                position={t("AboutUs.Leadership3.position")}
                imageSrc={PaolaCortes}
              />
            </div>
          </div>

          <div className="w-full lg:w-[91%] 2xl:max-w-screen-2xl flex flex-col gap-4 items-center px-6 lg:px-10">
            <h1 className="text-3xl md:text-5xl font-bold w-full mt-10 text-center mb-10">
              {t("AboutUs.LeadersSection")}
            </h1>
            <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-10">
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader1.name")}
                position={t("AboutUs.Leader1.position")}
                imageSrc={MarianaHuesca}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader2.name")}
                position={t("AboutUs.Leader2.position")}
                imageSrc={SalvadorRochin}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader3.name")}
                position={t("AboutUs.Leader3.position")}
                imageSrc={GustavoHerrera}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader4.name")}
                position={t("AboutUs.Leader4.position")}
                imageSrc={AndresLeal}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader5.name")}
                position={t("AboutUs.Leader5.position")}
                imageSrc={AlejandroCreel}
              />
            </div>

            <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-12 mt-10">
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader13.name")}
                position={t("AboutUs.Leader13.position")}
                imageSrc={JuanDavid}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader6.name")}
                position={t("AboutUs.Leader6.position")}
                imageSrc={PerlaAlvarado}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader7.name")}
                position={t("AboutUs.Leader7.position")}
                imageSrc={MarliaAlarcon}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader9.name")}
                position={t("AboutUs.Leader9.position")}
                imageSrc={BoomerCarr}
                imageClassName="mt-6"
              />
            </div>

            <div className="flex flex-wrap items-center justify-center gap-3 lg:gap-12 mt-10 mb-10">
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader8.name")}
                position={t("AboutUs.Leader8.position")}
                imageSrc={MarianaCuervo}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader10.name")}
                position={t("AboutUs.Leader10.position")}
                imageSrc={IsraelSosa}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader11.name")}
                position={t("AboutUs.Leader11.position")}
                imageSrc={RenneTriay}
              />
              <LeadersCard
                isGradient
                name={t("AboutUs.Leader12.name")}
                position={t("AboutUs.Leader12.position")}
                imageSrc={IlseMurillo}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
