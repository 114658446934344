import classNames from "classnames";
import React from "react";

type LeadersProps = {
  name: string;
  position: string;
  imageSrc: string;
  imageClassName?: string;
  isGradient?: boolean;
};

const LeadersCard = ({
  name,
  position,
  imageSrc,
  imageClassName,
  isGradient = false,
}: LeadersProps) => {
  return (
    <div
      className={`${classNames(
        `
        relative flex flex-col text-gray-700 bg-zinc-900 bg-clip-border shadow-md rounded-[40px]
        w-[180px] h-[290px] lg:w-[220px] lg:h-[315px] 2xl:w-[250px] 2xl:h-[440px]
        overflow-hidden
      `,
        {
          "!bg-[#24223d]": isGradient,
        }
      )}
    `}
    >
      {isGradient && (
        <>
          <div className="absolute -bottom-12 -right-20 w-64 h-64 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-white via-transparent to-transparent opacity-45" />
          <div className="absolute -bottom-16 -right-10 w-36 h-36 bg-[radial-gradient(circle_at_center,_var(--tw-gradient-stops))] from-black via-transparent to-transparent opacity-85" />
        </>
      )}
      <div className="relative flex-shrink-0 overflow-hidden text-white bg-white bg-clip-border rounded-br-[36px] rounded-bl-[36px] h-[180px] lg:h-[225px] 2xl:h-[284px]">
        <img
          src={imageSrc}
          alt={name}
          className={`object-cover ${imageClassName} h-full mt-2`}
        />
      </div>
      <div className="px-6 py-3">
        <div className="flex items-center justify-between">
          <p className="block text-[13px] mb-1 lg:text-[15px] 2xl:text-2xl lg:mb-0 antialiased font-semibold leading-relaxed text-white">
            {name}
          </p>
        </div>
        <p className="block text-[12px] lg:text-xs 2xl:text-base antialiased font-semibold leading-normal text-neutral-400 opacity-75">
          {position}
        </p>
      </div>
    </div>
  );
};

export default LeadersCard;
