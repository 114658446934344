import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Bars3Icon } from "@heroicons/react/24/outline";
import { useOutsideClick } from "@/hooks/useOutsideClick";
import classNames from "classnames";
import { Button } from "@/components/Button";
import { LanguageSelector } from "@/components/LanguageSelector";
import { LanguageCard } from "@/components/LanguageCard";
import sendolaLogo from "@/assets/Img/logo-sendola.png";
import SendolaBlackLogoCompact from "@/assets/Img/sendola-black-logo-compact.png";

const menuItem =
  "flex w-full gap-2 items-center py-3 text-white hover:text-purple-300 cursor-pointer border-b-[1px] border-b-gray-600 last:border-b-0";
const navItem = "text-sm xl:text-base hover:text-gray-300 flex-shrink-0";

const AuthUrl = import.meta.env.VITE_AUTH_DOMAIN;
const SendolaBlack = import.meta.env.VITE_SENDOLA_BLACK_URL;

export const Header = () => {
  const [t, i18n] = useTranslation("global");
  const currentLanguage = i18n.language;
  const pathname = window.location?.href;
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const [menuTouched, setMenuTouched] = useState<boolean>(false);
  const [scrollDirection, setScrollDirection] = useState<"down" | "up">("up");
  const [showLanguageBar, setShowLanguageBar] = useState<boolean>(false);
  const [visible, setVisible] = useState(true);

  const ref = useOutsideClick(() => {
    if (menuOpen) setMenuOpen(false);
  });
  const isGradientRoute = useMemo(() => {
    if (pathname.includes("/why-sendola")) return true;
    if (pathname.includes("/how-it-works")) return true;
    if (pathname.includes("/for-partners")) return true;
    if (pathname.includes("/about-us")) return true;

    return false;
  }, [pathname]);

  useEffect(() => {
    let lastScrollY = window.scrollY;

    const updateScrollDirection = () => {
      const scrollY = window.scrollY;
      const direction = scrollY > lastScrollY ? "down" : "up";

      if (
        direction !== scrollDirection &&
        (scrollY - lastScrollY > 5 || scrollY - lastScrollY < -5)
      ) {
        setScrollDirection(direction);
        setVisible(direction === "up");
      }
      lastScrollY = scrollY > 0 ? scrollY : 0;
    };

    window.addEventListener("scroll", updateScrollDirection);

    return () => {
      window.removeEventListener("scroll", updateScrollDirection);
    };
  }, [scrollDirection]);

  useEffect(() => {
    if (!currentLanguage) setShowLanguageBar(true);
  }, [currentLanguage]);

  useEffect(() => {
    if (!visible) {
      setMenuOpen(false);
    }
  }, [visible]);

  return (
    <>
      <nav
        className={classNames(
          "z-50 sticky top-0 transition-all duration-200 ease-in",
          {
            "-translate-y-20 lg:translate-y-0": !visible,
            "translate-y-0": visible,
          }
        )}
      >
        <div
          className={classNames(
            "w-full flex justify-between items-center transition-all bg-gradient-to-r from-black from-60% to-[#6739dc]",
            {
              "h-16 px-4 py-2": true, // Mobile styles
              "lg:px-10 lg:h-20": true, // Desktop styles
              "lg:!to-transparent lg:bg-gradient-to-b lg:from-40% lg:-mb-20":
                isGradientRoute,
            }
          )}
        >
          <LanguageSelector
            className="lg:hidden"
            onClick={() => setShowLanguageBar(!showLanguageBar)}
          />

          <Link to="/">
            <img
              className="h-10 lg:h-14 w-auto object-contain mr-4"
              src={sendolaLogo}
              alt="Sendola's Logo"
            />
          </Link>
          <div ref={ref} className="w-16 flex justify-end lg:hidden">
            <Bars3Icon
              className={classNames(
                "w-10 h-10 rounded text-white cursor-pointer lg:hover:bg-purple-950 p-2",
                {
                  "bg-purple-950": menuTouched,
                }
              )}
              onClick={() => setMenuOpen(!menuOpen)}
              onTouchStart={() => setMenuTouched(true)}
              onTouchEnd={() => setMenuTouched(false)}
            />
          </div>

          <div className="hidden h-full lg:flex items-center gap-6 text-white">
            <Link to="/how-it-works">
              <h1 className={navItem}>{t("Header.HowItWorks")}</h1>
            </Link>
            <Link to="/why-sendola">
              <h1 className={navItem}>{t("Header.WhySendola")}</h1>
            </Link>
            <Link to="/for-partners">
              <h1 className={navItem}>{t("Header.ForPartners")}</h1>
            </Link>
            <Link to="/about-us">
              <h1 className={navItem}>{t("Header.AboutUs")}</h1>
            </Link>
            <Link to={SendolaBlack}>
              <img
                className="object-contain h-8 hover:opacity-90"
                src={SendolaBlackLogoCompact}
                alt="Sendola black logo compack"
              />
            </Link>

            <div className="flex gap-4">
              <LanguageSelector
                className="hidden lg:block"
                onClick={() => setShowLanguageBar(!showLanguageBar)}
              />
              <Button
                className="bg-white !text-black !border-0 hover:bg-indigo-100 hover:opacity-100"
                size="sm"
                text={t("Header.SignupButton")}
                link={`${AuthUrl}/sign-up`}
              />
              <Button
                variant="secondary"
                className="bg-transparent border-white text-white hover:bg-white/10"
                size="sm"
                text={t("Header.LoginButton")}
                link={`${AuthUrl}/sign-in`}
              />
            </div>
          </div>
        </div>

        <div
          className={classNames(
            "absolute overflow-hidden transition-all w-max min-w-60 rounded-b-lg flex-col bg-black/85 shadow-lg px-4 top-16 right-0 z-20 box-border",
            {
              "max-h-80": menuOpen,
              "max-h-0": !menuOpen,
            }
          )}
        >
          <Link to="/how-it-works" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.HowItWorks")}</p>
          </Link>
          <Link to="/why-sendola" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.WhySendola")}</p>
          </Link>
          <Link to="/for-partners" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.ForPartners")}</p>
          </Link>
          <Link to="/about-us" className={menuItem}>
            <p className="text-sm lg:text-base">{t("Header.AboutUs")}</p>
          </Link>
          <a
            href="https://api.whatsapp.com/send?phone=18325251941&text=Hola!"
            className={menuItem}
          >
            <p className="text-sm lg:text-base">{t("Header.Assistant")}</p>
          </a>

          <Link to={SendolaBlack}>
            <img
              className="object-contain h-12 py-2 hover:opacity-80"
              src={SendolaBlackLogoCompact}
              alt="Sendola black logo compack"
            />
          </Link>
        </div>
      </nav>

      <LanguageCard
        show={showLanguageBar}
        onClose={() => setShowLanguageBar(false)}
      />
    </>
  );
};
